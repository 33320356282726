<template>
    <!-- 导航 -->
    <page-head title="视频资料"/>
    <content-bg>
        <template v-slot:content>
            <p class="title">视频资料</p>
            <div class="list">
                <div v-for="(item, index) in showData.showList" :key="item.name" class="item" @click="details(item)">
                    <!-- 序号 -->
                    <index-number
                        class="number"
                        spanColor="rgba(255, 255, 255, 0.3)"
                        iColor="rgba(255, 255, 255, 0.8)"
                    >
                        <template v-slot:number>
                            <span style="color: #602B89;" class="akrobatBold">{{ index + 1 }}</span>
                        </template>
                    </index-number>
                    <!-- 封面 -->
                    <video class="img" :src="item.url"></video>
                    <!-- 视频名 -->
                    <div class="item_box">
                        <p class="item_name">
                            {{ item.name }}
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </content-bg>
    <pagination-com :current-page="showData.page" :total-page="showData.totalPage"
                    @changePage="changePage"></pagination-com>

</template>

<script>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";

export default {
    name: "video",

    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const {proxy} = getCurrentInstance();

        const state = reactive({
            videoData: [
                {
                    name: '中国足球协会宣传视频1',
                    url: require('@i/introduce/video/1.mp4'),
                },
                {
                    name: '中国足球协会宣传视频2',
                    url: require('@i/introduce/video/2.mp4'),
                },
                {
                    name: '中国足球协会宣传视频3',
                    url: require('@i/introduce/video/3.mp4'),
                },
                {
                    name: 'NIKE与中国足协发起女足纪录片《新的名字》',
                    url: require('@i/introduce/video/4.mp4'),
                }
            ],

            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 8
            },
            showData: {}
        })

        onMounted(() => {
            state.showData = proxy.$utils.paginationFn(state.videoData, state.page.pageNum, state.page.pageSize);
        })

        const changePage = (page) => {
            state.page.pageNum = page;
            state.showData = proxy.$utils.paginationFn(state.videoData, state.page.pageNum, state.page.pageSize);
        }

        const details = (row) => {
            router.push({
                path: '/videoList/details',
                query: {
                    url: row.url,
                    name: row.name
                }
            })
        }

        return {
            ...toRefs(state),

            changePage,
            details
        }
    }
}
</script>

<style scoped lang="scss">
.title {
    padding-top: 44px;
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
}

.list {
    display: grid;
    grid-template-columns: repeat(4, 291px);
    grid-template-rows: auto;
    justify-content: space-between;
    padding: 32px 30px 0;
    box-sizing: border-box;

    .item {
        position: relative;
        width: 100%;
        height: 215px;
        cursor: pointer;
        overflow: hidden;
        background-color: rgba(216, 216, 216, .1);
        border: 1.11923px solid rgba(245, 248, 255, .1);
        box-shadow: 0px 17.9077px 17.9077px rgba(0, 50, 174, 0.08);
        border-radius: 6px;
        margin-bottom: 50px;

        .number {
            position: absolute;
            top: 13px;
            left: 13px;
        }

        .img {
            width: 100%;
            height: 162px;
            border-radius: 6px;
        }

        .item_box {
            margin-top: 12px;
            color: #fff;
            font-size: 24px;
            padding-left: 12px;
            padding-right: 12px;
            box-sizing: border-box;

            .item_name {
                word-break: break-all;
                width: 100%;
                min-height: 68px;
                line-height: 34px;
                opacity: 0.8;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}
</style>